import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../Assets/churchHome.jpeg";
import Home2 from "./Home2";
import Type from "./Type";
import { Link } from "react-router-dom";

function PortfolioHome() {
  return (
    <div>
      <Container fluid className="home-section">
        <Container className="home-content">
          <Row>
            <Col xs={12} sm={12} md={6} className="home-header">
              <h1 style={{ paddingBottom: 20 }} className="heading">
                Welcome To Our Church! <span className="wave" role="img" aria-labelledby="wave">👋🏻</span>
              </h1>
              <h1>             
                <strong className="green">Duquesne Heights Christian Church</strong>
              </h1>
              <h3>
                <p style={{ marginLeft: 50}}>
                  <strong>Shaler Street and Rutledge Street</strong>
                </p>
                <p style={{ marginLeft: 50}}>
                  <strong>Pittsburgh, PA 15211-1042</strong>
                </p>
              </h3>

              <div style={{ paddingTop: 20, paddingBottom: 20,textAlign: "left" }}>
                <Type />
              </div>
            </Col>
            <br/>
            <br/>
            <Col xs={12} sm={12} md={6} style={{ paddingBottom: 20, paddingTop: 20}}>
              <img src={homeLogo} style={{border: "4px solid #777777"}} alt="home pic" className="img-fluid" width="400" height="400"/>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </div>
  );
}

export default PortfolioHome;
