import React, { Component } from "react";
import PortfolioHome from "./Home";
import About from "./aboutComponent";
import General from "./generalComponent";
import Header from "./headerComponent";
import Footer from "./footerComponent";
import Things from "./thingsComponent";
import Sermons from "./sermonsComponent";
import SermonDetail from "./sermondetailComponent";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from "styled-components";


const mapStateToProps = state => {
  return {
    sermons: state.sermons
  }
}


class Main extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const SermonWithID = ({ match }) => {
      return (
        <SermonDetail sermon={this.props.sermons.filter((sermon) => sermon.id === parseInt(match.params.sermon, 10))}
          sermons={this.props.sermons.filter((sermon) => sermon.id === parseInt(match.params.sermon, 10))}/>
      )
    }

    return (
      <div>
        <Header/>
        <Switch>
          <Route path="/home" component={PortfolioHome} />
          <Route path="/about" component={About} />
          <Route path="/general_info" component={General} />
          <Route path="/thingstoknow" component={Things} />
          <Route exact path="/sermons" component={()=><Sermons sermons={this.props.sermons}/>} />
          <Route path = "/sermons/:sermonID" component={SermonWithID} />
          <Redirect to="/home" />
        </Switch>
      </div>
    )
  }
}
// export default Main;
export default withRouter(connect(mapStateToProps)(Main));
