export const SERMONS =

[
    {
        id: 0,
        detail: "Sermon 1",
        file: "assets/sermons/FILE_001.MP3"
    },
    {
        id: 1,
        detail: "Sermon 2",
        file: "assets/sermons/FILE_002.MP3"
    },
    {
        id: 2,
        detail: "Sermon 3",
        file: "assets/sermons/FILE_003.MP3"
    },
    {
        id: 3,
        detail: "Sermon 4",
        file: "assets/sermons/FILE_004.MP3"
    },
    {
        id: 4,
        detail: "Sermon 5",
        file: "assets/sermons/FILE_005.MP3"
    },
    {
        id: 5,
        detail: "Sermon 6",
        file: "assets/sermons/FILE_006.MP3"
    },
    {
        id: 6,
        detail: "Sermon 7",
        file: "assets/sermons/FILE_007.MP3"
    },
    {
        id: 7,
        detail: "Sermon 8",
        file: "assets/sermons/FILE_008.MP3"
    },
    {
        id: 8,
        detail: "Sermon 9",
        file: "assets/sermons/FILE_009.MP3"
    },
    {
        id: 9,
        detail: "Sermon 10",
        file: "assets/sermons/FILE_010.MP3"
    },
    {
        id: 10,
        detail: "Sermon 11",
        file: "assets/sermons/FILE_011.MP3"
    },
    {
        id: 11,
        detail: "Sermon 12",
        file: "assets/sermons/FILE_012.MP3"
    },
    {
        id: 12,
        detail: "Sermon 13",
        file: "assets/sermons/FILE_013.MP3"
    },
    {
        id: 13,
        detail: "Sermon 14",
        file: "assets/sermons/FILE_014.MP3"
    },
    {
        id: 14,
        detail: "Sermon 15",
        file: "assets/sermons/FILE_015.MP3"
    },
    {
        id: 15,
        detail: "Sermon 16",
        file: "assets/sermons/FILE_016.MP3"
    },
    {
        id: 16,
        detail: "Sermon 17",
        file: "assets/sermons/FILE_017.MP3"
    },
    {
        id: 17,
        detail: "Sermon 18",
        file: "assets/sermons/FILE_018.MP3"
    },
    {
        id: 18,
        detail: "Sermon 19",
        file: "assets/sermons/FILE_019.MP3"
    },
    {
        id: 19,
        detail: "Sermon 20",
        file: "assets/sermons/FILE_020.MP3"
    },
    {
        id: 20,
        detail: "Sermon 21",
        file: "assets/sermons/FILE_021.MP3"
    },
    {
        id: 21,
        detail: "Sermon 22",
        file: "assets/sermons/FILE_022.MP3"
    },
    {
        id: 22,
        detail: "Sermon 23",
        file: "assets/sermons/FILE_023.MP3"
    },
    {
        id: 23,
        detail: "Sermon 24",
        file: "assets/sermons/FILE_024.MP3"
    },
    {
        id: 24,
        detail: "Sermon 25",
        file: "assets/sermons/FILE_025.MP3"
    },
    {
        id: 25,
        detail: "Sermon 26",
        file: "assets/sermons/FILE_026.MP3"
    },
    {
        id: 26,
        detail: "Sermon 27",
        file: "assets/sermons/FILE_027.MP3"
    },
    {
        id: 27,
        detail: "Sermon 28",
        file: "assets/sermons/FILE_028.MP3"
    },
    {
        id: 28,
        detail: "Sermon 29",
        file: "assets/sermons/FILE_029.MP3"
    },
    {
        id: 29,
        detail: "Sermon 30",
        file: "assets/sermons/FILE_030.MP3"
    },
    {
        id: 30,
        detail: "Sermon 31",
        file: "assets/sermons/FILE_031.MP3"
    },
    {
        id: 31,
        detail: "Sermon 32",
        file: "assets/sermons/FILE_032.MP3"
    },
    {
        id: 32,
        detail: "Sermon 33",
        file: "assets/sermons/FILE_033.MP3"
    },
    {
        id: 33,
        detail: "Sermon 34",
        file: "assets/sermons/FILE_034.MP3"
    },
    {
        id: 34,
        detail: "Sermon 35",
        file: "assets/sermons/FILE_035.MP3"
    },
    {
        id: 35,
        detail: "Sermon 36",
        file: "assets/sermons/FILE_036.MP3"
    },
    {
        id: 36,
        detail: "Sermon 37",
        file: "assets/sermons/FILE_0037.MP3"
    },
    {
        id: 37,
        detail: "Sermon 38",
        file: "assets/sermons/FILE_038.MP3"
    },
    {
        id: 38,
        detail: "Sermon 39",
        file: "assets/sermons/FILE_039.MP3"
    },
    {
        id: 39,
        detail: "Sermon 40",
        file: "assets/sermons/FILE_040.MP3"
    },
    {
        id: 40,
        detail: "Sermon 41",
        file: "assets/sermons/FILE_041.MP3"
    },
    {
        id: 41,
        detail: "Sermon 42",
        file: "assets/sermons/FILE_042.MP3"
    },
    {
        id: 42,
        detail: "Sermon 43",
        file: "assets/sermons/FILE_043.MP3"
    },
    {
        id: 43,
        detail: "Sermon 44",
        file: "assets/sermons/FILE_044.MP3"
    },
    {
        id: 44,
        detail: "Sermon 45",
        file: "assets/sermons/FILE_045.MP3"
    },
    {
        id: 45,
        detail: "Sermon 46",
        file: "assets/sermons/FILE_046.MP3"
    },
    {
        id: 46,
        detail: "Sermon 47",
        file: "assets/sermons/FILE_047.MP3"
    },
    
]