import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Things(){
    return (
        <Container fluid className="home-about-section">
            <Row>
                <Col sm={10} md={10} className="general-info-description" >
                    <h3 className="green">
                        Things to know...
                    </h3>
                    <p className="green">The Following are some highlights of what our Church does:</p>
                    <ul>
                        <li>Open Communion the 1st Sunday of every month.</li>
                        <li>Helping Hands Endowment -- gift cards given out at Christmas for the needy</li>
                        <li>Support four missionaries helping folks around the world come to Christ</li>
                        <li>Donate usable household items to charity during Lent</li>
                        <li>Collect pennies/coins to give to several Pittsburgh charities at Christmas</li>
                        <li>Support local food bank in Mt. Washington</li>
                        <li>Open door policy supporting the community</li>
                        <li>Sing the "old" hymns that all will recognize</li>
                        <li>Ladies of the church make homemade cookies for the AA group</li>
                        <li>Christmas Eve Service each year</li>
                        <li>Live organ music</li>
                        <li>Handicap accessible...behind church building is a ramp</li>
                        <li>Thursday night the AA meeting starts at 7:00 p.m.</li>
                        <li>We have air conditioning...come in and stay cool</li>
                    </ul>
                </Col>
            </Row>
            <Row style={{ paddingBottom: 50}}>
                <Col sm={10} md={10} className="general-info-description">
                    <h3 className="green">
                        <strong>
                            Prayer Requests 
                        </strong>
                    </h3>
                    <p style={{ marginLeft: 25}}>
                        Questions/concerns/prayer requests can be sent to renori1@comcast.net
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default Things;