import React from "react";
import { Container, Row, Col, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome, FaAddressCard } from 'react-icons/fa';


function Header(){
    return(
        <div className="header">
            <Col>
                <Container>
                    <NavItem className="nav-link">
                        <a><FaHome/></a><Link to='/home'>Home</Link>
                    </NavItem>
                    <NavItem className="nav-link">
                        <a><FaAddressCard/></a><Link to='/about'>About</Link>
                    </NavItem>
                    <NavItem className="nav-link">
                        <a><FaAddressCard/></a><Link to='/general_info'>General Info</Link>
                    </NavItem>
                    <NavItem className="nav-link">
                        <a><FaAddressCard/></a><Link to='/thingstoknow'>Things to Know</Link>
                    </NavItem>
                    <NavItem className="nav-link">
                        <a><FaAddressCard/></a><Link to='/sermons'>Recorded Sermons</Link>
                    </NavItem>
                </Container>
            </Col>
        </div>

    )
}
export default Header;