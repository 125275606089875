import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "John 3:16: For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life",
          "Jeremiah 29:11: For I know the plans I have for you,” declares the LORD, “plans to prosper you and not to harm you, plans to give you hope and a future",
          "Romans 8:28: And we know that in all things God works for the good of those who love him, who have been called according to his purpose",
          "Proverbs 3:5: Trust in the LORD with all your heart and lean not on your own understanding",
        ],
        autoStart: true,
        loop: true,
        delay: 30,
      }}
    />
  );
}

export default Type;
