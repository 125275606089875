import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg2 from "../Assets/church1.jpg";
import imgBob from "../Assets/bob.jpeg";
import imgJosh from "../Assets/josh.jpeg"
import { Link } from "react-router-dom";

function About() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Row>
                <Col md={1}>
                    <br/>  
                </Col>
                <Col xs={12} md={6} className="home-about-description">
                    <h1 style={{ fontSize: "2.6em" }}>
                        <span className="green"> About The Pastor </span> 
                    </h1>
                    <img src={imgBob} className="img-fluid" width="400" height="500"/>
                    <h3 className="Pastorname">
                        Bob Reno
                    </h3>
                    <p>
                        My name is Robert Reno. I am the Pastor of the Duquesne Heights Christian Church 
                        in Mt. Washington, Pennsylvania. The congregation refers to me as Pastor Bob. 
                        Upon graduating high school, I entered the U.S. Navy where I was educated in valuable 
                        life lessons. I learned how to work with people from different backgrounds and cultures
                        in all parts of the globe. I was fortunate to learn structure, discipline and 
                        submission to authority - all valuable tools for anyone. After being discharged from 
                        the Navy I was hired by United Parcel Service. I went from one uniform to another. Working
                        for UPS allowed me to interact with the public and develop positive people skills. In 
                        1968, Janet Shipley and I were married and together we raised three lovely daughters (that's
                        why my remaining hair is gray)! In 1975, Jan and I received Christ as Lord and Savior. All we wanted
                        to do was to serve Him and love those He came to save. Shortly thereafter we joined Faith Community Church
                        in Bethel Park, PA. In 1980, I became the assitant youth Pastor and in 1982 I was ordained as an elder 
                        at the same church. In 1988, I completed a course of study at Faith Community Seminary which
                        was overseen by Dr. James Laine, PhD. Dr. Laine taught me Systematic Theology and how to lives
                        by my convictions and preach the Word of God. in 2001, I was ordained as a Minister of the Gospel
                        of Jesus Christ and have served as the Pastor of the Duquesne Heights Christian Church since that time.
                    </p>
                    <img src={imgJosh} className="img-fluid" width="400" height="500"/>
                    <h3 className="Pastorname">
                        Joshua Nirella
                    </h3>
                    <p>
                        I have been ministering to people in many capacities since 1994 when i took my first mission trip 
                        to New York City. I have been on several mission trips since then, including Latvia and Mexico City, where 
                        I proposed to my college sweetheart in 2001. I have served as a youth pastor in several churches (along with 
                        worship leader and lay pastor), and obtained my Minister's Certification through the Assemblies of God. I 
                        have been ministering at the Duquesne Heights Christian Church since 2008 and became an Ordained Minister here 
                        in 2016. My wife, Laura and I have been married since 2002, have five children, and have been in ministry in many 
                        different places over our lives. As a minister, I believe that the Bible is inspired by God and is relevant for 
                        today's world. My favorite verse is Ephesians 2:8-9.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default About;