import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../Assets/avatar.jpg";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn, FaRegPlusSquare } from "react-icons/fa";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col xs={12} md={6} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="green"> Service Time: 10:00 AM each Sunday </span> 
            </h1>
            <p>
              Pastor Robert Reno   (412) 886 7332
            </p>
            <p>
              Pastor Joshua Nirella   (412) 506-1722
            </p>
          </Col>
            
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
