import React from "react";
import { Card, CardImg, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

const SermonDetail = (props) => {
    return (
        <div>
            <p>Blah</p>
        </div>
    )
}

export default SermonDetail;