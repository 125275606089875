import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function General(){
    return (
        <Container fluid className="home-about-section" id="about">
            <Row>
                <Col sm={10} md={10} className="general-info-description">
                    <h3>Board of Directors</h3>
                    <p>
                        Church Council
                    </p>
                    <Col md={4}>
                        <p>
                            Sandra Manion, President
                        </p>
                        <p>
                            Donna Davison, Vice President
                        </p>
                        <p>
                            Arlene Shanks, Secretary/Treasurer
                        </p>
                        <p>
                            Dorothy Vollberg
                        </p>
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col sm={10} md={10} className="general-info-description">
                    <h3>Weddings</h3>
                    <p>
                        Our church is open for weddings. (Remember we are at a great location for pictures of the city!!)
                    </p>
                    <p>
                        Our fees are as follows for planning your wedding day with us.
                    </p>
                    <ul>
                        <li>Pastor -- $150</li>
                        <li>Organist -- $100</li>
                        <li>Janitor -- $50</li>
                        <li>Utilities -- $100</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}
export default General;