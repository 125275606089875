import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card, CardImg, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function RenderSermonItem({ sermon }){
    console.log(sermon.file);
    return(
        <Container>
            <h3 style={{color: 'green'}}>{sermon.detail}</h3>
            <div>
                <AudioPlayer
                    src={sermon.file}
                />
            </div>
        </Container>
    )
}

const Sermons = (props) => {
    console.log(props)

    const sermons = props.sermons.map((sermon) => {
        return (
            <div key={sermon.id} className="col-12 col-md-4">
                <Col>
                    <RenderSermonItem sermon={sermon} />
                </Col>
            </div>
        )
    })

    return (
        <Container fluid className="home-about-section" id="sermons">
            <Row>
                <div>
                    {sermons}
                </div>
            </Row> 
        </Container>
    )

}

export default Sermons;